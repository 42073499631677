import React from 'react';
import classes from './Button.module.scss';
import gsap from 'gsap';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

const Button = ({ children, telegram }) => {
  const { t } = useTranslation();

  document.querySelectorAll(`.${classes.button}`).forEach((button) => {
    if (document.documentElement.clientWidth > 1219) {
      let gradientElem = button.querySelector(`.${classes.gradient}`);

      if (!gradientElem) {
        gradientElem = document.createElement('div');
        gradientElem.classList.add(`${classes.gradient}`);

        button.appendChild(gradientElem);
      }

      button.addEventListener('pointermove', (e) => {
        const rect = button.getBoundingClientRect();

        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        gsap.to(button, {
          '--pointer-x': `${x}px`,
          '--pointer-y': `${y}px`,
          duration: 0.6,
        });

        gsap.to(button, {
          '--button-glow': chroma
            .mix(
              getComputedStyle(button)
                .getPropertyValue('--button-glow-start')
                .trim(),
              getComputedStyle(button)
                .getPropertyValue('--button-glow-end')
                .trim(),
              x / rect.width
            )
            .hex(),
          duration: 0.2,
        });
      });
    }
  });

  const toForm = () => {
    const element = document.getElementById('home_form');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  if (telegram) {
    return (
      <a
        href="https://t.me/Danil_ubt"
        target="_blank"
        rel="noreferrer"
        className={`${classes.button} no-select`}
      >
        <span>
          {t('btn.contact_us')}
        </span>
      </a>
    );
  }

  return (
    <div onClick={toForm} className={`${classes.button} no-select`}>
      <span>{children}</span>
    </div>
  );
};

export default Button;
