import _ from 'lodash';
import React, { useState, useEffect, useRef } from "react";
import classes from './Form.module.scss';
import { Trans, useTranslation } from "react-i18next";
import FormGroup from "./FormGroup";
import axios from 'axios';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Form = () => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [contacts, setContacts] = useState('');
    const [agree, setAgree] = useState(false);

    const [nameValid, setNameValid] = useState(true);
    const [positionValid, setPositionValid] = useState(true);
    const [contactsValid, setContactsValid] = useState(true);
    const [agreeValid, setAgreeValid] = useState(true);

    const [success, setSuccess] = useState(false);

    const setDataDefault = () => {
        setName('');
        setPosition('');
        setContacts('');
        setAgree(false);
        setNameValid(true);
        setPositionValid(true);
        setContactsValid(true);
        setAgreeValid(true);
        setSuccess(false);
    };

    const validateString = (getter, setter) => {
        if (getter === '') {
            setter(false);
            return false;
        }
        setter(true);
        return true;
    };
    const validateAgree = () => {
        if (agree === false) {
            setAgreeValid(false);
            return false;
        }
        setAgreeValid(true);
        return true;
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(validateString(name, setNameValid));
        validArr.push(validateString(position, setPositionValid));
        validArr.push(validateString(contacts, setContactsValid));
        validArr.push(validateAgree());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };

    const sendMessage = async () => {
        const fields = {
            name,
            position,
            contacts,
        };
        setDataDefault();
        try {
            const data = await axios.post('https://bestroitraffic.com/form.php', JSON.stringify(fields));
            if (data.status === 200) {
                setSuccess(true);
            } else {
                alert('Something went wrong. Try again later.');
                setSuccess(false);
            }
        } catch (error) {
            alert('Something went wrong. Try again later.');
            setSuccess(false);
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        !nameValid && validateString(name, setNameValid);
        // eslint-disable-next-line
    }, [name]);
    useEffect(() => {
        !positionValid && validateString(position, setPositionValid);
        // eslint-disable-next-line
    }, [position]);
    useEffect(() => {
        !contactsValid && validateString(contacts, setContactsValid);
        // eslint-disable-next-line
    }, [contacts]);
    useEffect(() => {
        !agreeValid && validateAgree();
        // eslint-disable-next-line
    }, [agree]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className={classes.formContainer}>
            <div className="mt container">
                <div className={classes.formWrap}>
                    <form id="home_form" className={`${classes.form} ${success ? classes.formSuccess : ''}`}>
                        <div className={classes.successPopup}>
                            <div className={classes.successPopupImg}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                    <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#FFF" />
                                </svg>
                            </div>
                            <p className={`${classes.successPopupText} font-18`}>
                                <Trans>
                                    {t('form.popuptext')}
                                </Trans>
                            </p>
                        </div>
                        <FormGroup
                            type="text"
                            value={name}
                            setValue={setName}
                            valid={nameValid}
                            placeholder={t('form.name')}
                        />
                        <FormGroup
                            type="text"
                            value={position}
                            setValue={setPosition}
                            valid={positionValid}
                            placeholder={t('form.position')}
                        />
                        <FormGroup
                            type="text"
                            value={contacts}
                            setValue={setContacts}
                            valid={contactsValid}
                            placeholder={t('form.contacts')}
                        />
                        <div className={`${classes.check} ${!agreeValid && classes.checkRed}`}>
                            <div
                                className={`${classes.checkBox} ${agree ? classes.checkBoxActive : ''}`}
                                onClick={() => setAgree((value) => !value)}
                            ></div>
                            <p className={classes.checkText}>
                                {/* eslint-disable-next-line */}
                                <Trans components={{ a: <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank"></a> }}>
                                    {t('form.agree')}
                                </Trans>
                            </p>
                        </div>
                        <div className={classes.submit}>
                            <div
                                onClick={submit}
                                className={classes.btn}
                            >
                                {t('btn.submit')}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Form;
