import React, { useState, useEffect, useRef } from "react";
import classes from './Exceptional.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/Exceptional/image.avif';
import stone_01 from '../../../assets/img/HomePage/Exceptional/stone_01.webp';
import stone_02 from '../../../assets/img/HomePage/Exceptional/stone_02.webp';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Exceptional = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const itemWrapRef_01 = useRef(null);
    const itemWrapRef_02 = useRef(null);
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const animateItem = (wrap, target, y, x) => {
        gsap.timeline().fromTo(target.current, {
            y, x
        }, {
            y: 0,
            x: 0,
            scrollTrigger: {
                trigger: wrap.current,
                start: "top bottom",
                end: "+=400px",
                scrub: 4,
                once: true,
            }
        });
    };

    useEffect(() => {
        if (windowWidth > 1220) {
            setTimeout(() => {
                animateItem(itemWrapRef_01, itemRef_01, 60, -20);
                animateItem(itemWrapRef_02, itemRef_02, 150, 10);
            }, 300);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-74`}>
                        <Trans>
                            {t('exceptional.title')}
                        </Trans>
                    </h2>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.contact_us')}
                        </Button>
                    </div>
                    <p className={`${classes.text} font-18`}>
                        <Trans>
                            {t('exceptional.text')}
                        </Trans>
                    </p>
                </div>
                <img className={classes.image} src={image} alt='' />
                <div ref={itemWrapRef_01} className={`${classes.stone} ${classes.stone_01}`}>
                    <img ref={itemRef_01} src={stone_01} alt='' />
                </div>
                <div ref={itemWrapRef_02} className={`${classes.stone} ${classes.stone_02}`}>
                    <img ref={itemRef_02} src={stone_02} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Exceptional;
