import React from 'react';
import classes from './Footer.module.scss';
import logo from '../../../assets/img/logo_black.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={classes.footerWrap}>
      <div className="mt container">
        <div className={classes.footer}>
          <div className={classes.content}>
            <div className={classes.header}>
              <div className={classes.left}>
                <div className={classes.logo}>
                  <img src={logo} alt="" />
                </div>
              </div>
              <div className={classes.right}>
                <a href="mailto:#" target="_blank" rel="noreferrer">
                  <svg
                    width="36"
                    height="38"
                    viewBox="0 0 36 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="dashicons:email-alt">
                      <path
                        id="Vector"
                        d="M34.1003 27.5501V10.4501C34.1003 8.8731 32.8272 7.6001 31.2502 7.6001H4.63125C3.05425 7.6001 1.78125 8.8731 1.78125 10.4501V27.5501C1.78125 29.1271 3.05425 30.4001 4.63125 30.4001H31.2502C32.8272 30.4001 34.1003 29.1271 34.1003 27.5501ZM31.6113 10.2411C32.2383 10.8681 31.8962 11.5141 31.5543 11.8371L23.8403 18.9051L31.2502 26.6191C31.4783 26.8851 31.6302 27.3031 31.3642 27.5881C31.1172 27.8921 30.5473 27.8731 30.3003 27.6831L21.9972 20.5961L17.9312 24.3011L13.8842 20.5961L5.58125 27.6831C5.33425 27.8731 4.76425 27.8921 4.51725 27.5881C4.25125 27.3031 4.40325 26.8851 4.63125 26.6191L12.0412 18.9051L4.32725 11.8371C3.98525 11.5141 3.64325 10.8681 4.27025 10.2411C4.89725 9.6141 5.54325 9.9181 6.07525 10.3741L17.9312 19.9501L29.8062 10.3741C30.3382 9.9181 30.9843 9.6141 31.6113 10.2411Z"
                        fill="#000"
                      />
                    </g>
                  </svg>
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <svg
                    width="38"
                    height="39"
                    viewBox="0 0 38 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.7909 6.68604C32.7909 6.68604 35.8665 5.48667 35.6092 8.39921C35.5245 9.59859 34.7558 13.7968 34.1573 18.337L32.1069 31.7874C32.1069 31.7874 31.9359 33.7579 30.3977 34.1007C28.8603 34.4427 26.5533 32.9013 26.1258 32.5585C25.7838 32.3012 19.7181 28.4458 17.5822 26.5616C16.9837 26.047 16.2997 25.0195 17.6677 23.8201L26.638 15.2527C27.6633 14.2251 28.6885 11.8263 24.4166 14.7389L12.4545 22.8772C12.4545 22.8772 11.0873 23.7346 8.52471 22.9635L2.97038 21.2495C2.97038 21.2495 0.919964 19.9647 4.42309 18.6798C12.9675 14.6534 23.4769 10.5415 32.7893 6.68604H32.7909Z"
                      fill="#000"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className={classes.docs}>
              <a href="/docs/TERMS_&_CONDITIONS.pdf">{t('footer.docs.terms')}</a>
              <a href="/docs/AML_POLICY.pdf">{t('footer.docs.aml')}</a>
              <a href="/docs/CLIENT_AGREEMENT.pdf">{t('footer.docs.agreement')}</a>
            </div>
            <div className={classes.under}>
              <div className={classes.rights}>
                {t('footer.rights')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
