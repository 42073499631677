import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import classes from './Header.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import i18n from 'i18next';
import $ from 'jquery';
import logo from '../../../assets/img/logo.svg';

const languages = [
  { value: 'en', label: 'EN' },
  { value: 'ru', label: 'RU' },
];

const Header = () => {
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const setLocale = (newLang) => {
    i18n.changeLanguage(newLang);
    navigate(`/${newLang}/${_.last(pathname.split('/'))}`);
  };

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);

  useEffect(() => {
    if ($('body').hasClass('lock')) {
      $('body').removeClass('lock');
    }
  }, []);

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
      return;
    }
    setLocale(lang);
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${classes.hideBg} no-select`}
    >
      <div className={['container', classes.container].join(' ')}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link to={`/${lang}/`}>
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowRight}>
            <a className={classes.socMedia} href="#" target="_blank" rel="noreferrer">
              <svg
                width="38"
                height="39"
                viewBox="0 0 38 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.7909 6.68604C32.7909 6.68604 35.8665 5.48667 35.6092 8.39921C35.5245 9.59859 34.7558 13.7968 34.1573 18.337L32.1069 31.7874C32.1069 31.7874 31.9359 33.7579 30.3977 34.1007C28.8603 34.4427 26.5533 32.9013 26.1258 32.5585C25.7838 32.3012 19.7181 28.4458 17.5822 26.5616C16.9837 26.047 16.2997 25.0195 17.6677 23.8201L26.638 15.2527C27.6633 14.2251 28.6885 11.8263 24.4166 14.7389L12.4545 22.8772C12.4545 22.8772 11.0873 23.7346 8.52471 22.9635L2.97038 21.2495C2.97038 21.2495 0.919964 19.9647 4.42309 18.6798C12.9675 14.6534 23.4769 10.5415 32.7893 6.68604H32.7909Z"
                  fill="#fff"
                />
              </svg>
            </a>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
