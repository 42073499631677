import React, { useState } from "react";
import classes from './Maximize.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Maximize/image_01.avif';
import image_02 from '../../../assets/img/HomePage/Maximize/image_02.avif';
import image_03 from '../../../assets/img/HomePage/Maximize/image_03.avif';
import image_04 from '../../../assets/img/HomePage/Maximize/image_04.avif';
import image_05 from '../../../assets/img/HomePage/Maximize/image_05.avif';
import image_06 from '../../../assets/img/HomePage/Maximize/image_06.avif';
import image_07 from '../../../assets/img/HomePage/Maximize/image_07.avif';
import comet from '../../../assets/img/HomePage/Maximize/comet.webp';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

const cards = [
    {
        image: image_01,
        title: 'maximize.card_01.title',
        text: 'maximize.card_01.text',
    },
    {
        image: image_02,
        title: 'maximize.card_02.title',
        text: 'maximize.card_02.text',
    },
    {
        image: image_03,
        title: 'maximize.card_03.title',
        text: 'maximize.card_03.text',
    },
    {
        image: image_04,
        title: 'maximize.card_04.title',
        text: 'maximize.card_04.text',
    },
    {
        image: image_05,
        title: 'maximize.card_05.title',
        text: 'maximize.card_05.text',
    },
    {
        image: image_06,
        title: 'maximize.card_06.title',
        text: 'maximize.card_06.text',
    },
    {
        image: image_07,
        title: 'maximize.card_07.title',
        text: 'maximize.card_07.text',
    },
];

const Maximize = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.cards}>
                <div className={classes.comet}>
                    <img src={comet} alt='' />
                </div>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-74`}>
                        <Trans>
                            {t('maximize.title')}
                        </Trans>
                    </h2>
                    <p className={`${classes.text} font-18`}>
                        <Trans>
                            {t('maximize.text')}
                        </Trans>
                    </p>
                </div>
                <div className={classes.navigation}>
                    <div
                        onClick={() => swiper.slidePrev()}
                        className={`${classes.nav} ${classes.navL}`}
                    ></div>
                    <div
                        onClick={() => swiper.slideNext()}
                        className={`${classes.nav} ${classes.navR}`}
                    ></div>
                </div>
                <div className={classes.swiperWrap}>
                    <Swiper
                        modules={[Navigation]}
                        className="cardsSwiper no-select"
                        spaceBetween={30}
                        slidesPerView={1}
                        grabCursor
                        onInit={(e) => { setSwiper(e) }}
                        onSlideChange={(e) => setActiveIndex(e.activeIndex)}
                        breakpoints={{
                            1220: {
                                slidesPerView: 3,
                                spaceBetween: 55,
                            },
                            900: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            480: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                        }}
                    >
                        {cards.map((card, index) =>
                            <SwiperSlide key={index} className="cardsSwiperSlide">
                                <div className={classes.card}>
                                    <div className={`${classes.imageContainer} ${index === activeIndex ? classes.active : ''}`}>
                                        <div className={classes.imageWrap}>
                                            <img className={classes.image} src={card.image} alt='' />
                                        </div>
                                    </div>
                                    <h4 className={`${classes.title} font-25`}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={`${classes.text} font-18`}>
                                        <Trans>
                                            {t(card.text)}
                                        </Trans>
                                    </p>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Maximize;
