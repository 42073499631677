import logo from '../assets/img/logo.svg';
import image_01 from '../assets/img/HomePage/Banner/bat.webp';
import image_02 from '../assets/img/HomePage/Banner/comet.webp';
import image_03 from '../assets/img/HomePage/Banner/planet.webp';
import image_04 from '../assets/img/HomePage/Banner/stone_01.webp';
import image_05 from '../assets/img/HomePage/Banner/stone_02.webp';
import image_06 from '../assets/img/HomePage/Banner/stone_03.webp';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
    image_04,
    image_05,
    image_06,
];
