import React from 'react';
import Header from '../components/GlobalComponents/Header/Header';
import Footer from '../components/GlobalComponents/Footer/Footer';
import Noise from '../components/GlobalComponents/Noise/Noise';
import Banner from '../components/HomePage/Banner/Banner';
import Maximize from '../components/HomePage/Maximize/Maximize';
import HowWorks from '../components/HomePage/HowWorks/HowWorks';
import Marquees from '../components/HomePage/Marquees/Marquees';
import Form from '../components/HomePage/Form/Form';
import Partner from '../components/HomePage/Partner/Partner';
import Step from '../components/HomePage/Step/Step';
import LetBest from '../components/HomePage/LetBest/LetBest';
import Exceptional from '../components/HomePage/Exceptional/Exceptional';
import AboutUs from '../components/HomePage/AboutUs/AboutUs';

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      {/* <Noise /> */}
      <Header />
      <Banner />
      <Exceptional />
      <Maximize />
      <LetBest />
      <HowWorks />
      <Marquees />
      <AboutUs />
      <Step />
      <Partner />
      <Form />
      <Footer />
    </div>
  );
};

export default HomePage;
