import React from "react";
import classes from './Partner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/Partner/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/Partner/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/Partner/icon_03.svg';

const cards = [
    {
        icon: icon_01,
        text: 'partner.card_01',
    },
    {
        icon: icon_02,
        text: 'partner.card_02',
    },
    {
        icon: icon_03,
        text: 'partner.card_03',
    },
];

const Partner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-74`}>
                    <Trans>
                        {t('partner.title')}
                    </Trans>
                </h2>
                <p className={`${classes.text} font-18`}>
                    {t('partner.text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.icon} src={card.icon} alt='' />
                            <p className={`${classes.desc} font-18`}>
                                <Trans>
                                    {t(card.text)}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Partner;