import React from "react";
import classes from './LetBest.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/let_best.avif';

const LetBest = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-74`}>
                        <Trans>
                            {t('let_best.title')}
                        </Trans>
                    </h2>
                    <p className={`${classes.text} font-25`}>
                        <Trans>
                            {t('let_best.text')}
                        </Trans>
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.contact_us')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LetBest;
