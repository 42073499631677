import React, { useState, useEffect, useRef } from "react";
import classes from './AboutUs.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image_01 from '../../../assets/img/HomePage/AboutUs/image_01.avif';
import image_02 from '../../../assets/img/HomePage/AboutUs/image_02.avif';
import image_03 from '../../../assets/img/HomePage/AboutUs/image_03.avif';
import image_04 from '../../../assets/img/HomePage/AboutUs/image_04.avif';
import stone_01 from '../../../assets/img/HomePage/AboutUs/stone_01.webp';
import stone_02 from '../../../assets/img/HomePage/AboutUs/stone_02.webp';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const cards = [
    {
        subtitle: 'about_us.card_01.subtitle',
        desc: 'about_us.card_01.text',
        image: image_01,
    },
    {
        subtitle: 'about_us.card_02.subtitle',
        desc: 'about_us.card_02.text',
        image: image_02,
    },
    {
        subtitle: 'about_us.card_03.subtitle',
        desc: 'about_us.card_03.text',
        image: image_03,
    },
    {
        subtitle: 'about_us.card_04.subtitle',
        desc: 'about_us.card_04.text',
        image: image_04,
    },
];

const AboutUs = () => {
    const { t } = useTranslation();
    const [swiper, setSwiper] = useState({});
    const [activeIndex, setActiveIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const itemWrapRef_01 = useRef(null);
    const itemWrapRef_02 = useRef(null);
    const itemRef_01 = useRef(null);
    const itemRef_02 = useRef(null);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const animateItem = (wrap, target, y, x) => {
        gsap.timeline().fromTo(target.current, {
            y, x
        }, {
            y: 0,
            x: 0,
            scrollTrigger: {
                trigger: wrap.current,
                start: "top bottom",
                end: "+=400px",
                scrub: 4,
                once: true,
            }
        });
    };

    useEffect(() => {
        if (windowWidth > 1220) {
            setTimeout(() => {
                animateItem(itemWrapRef_01, itemRef_01, 60, -20);
                animateItem(itemWrapRef_02, itemRef_02, 40, 110);
            }, 300);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-74`}>
                        {t('about_us.title')}
                    </h2>
                    <p className={`${classes.text} font-18`}>
                        <Trans>
                            {t('about_us.text')}
                        </Trans>
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.contact_us')}
                        </Button>
                    </div>
                </div>
                {windowWidth > 600 &&
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <img className={classes.image} src={card.image} alt='' />
                                <h4 className={`${classes.subtitle} font-20`}>
                                    <Trans>
                                        {t(card.subtitle)}
                                    </Trans>
                                </h4>
                                <p className={`${classes.desc} font-18`}>
                                    {t(card.desc)}
                                </p>
                            </div>
                        )}
                    </div>
                }
                {windowWidth <= 600 &&
                    <div className={classes.cardsMob}>
                        <div className={classes.navigation}>
                            <div
                                onClick={() => swiper.slidePrev()}
                                className={`${classes.nav} ${classes.navL}`}
                            ></div>
                            <div
                                onClick={() => swiper.slideNext()}
                                className={`${classes.nav} ${classes.navR}`}
                            ></div>
                        </div>
                        <div className={classes.swiperWrap}>
                            <Swiper
                                modules={[Navigation]}
                                className="aboutSwiper no-select"
                                spaceBetween={20}
                                slidesPerView={1}
                                grabCursor
                                onInit={(e) => { setSwiper(e) }}
                                onSlideChange={(e) => setActiveIndex(e.activeIndex)}
                                breakpoints={{
                                    480: {
                                        slidesPerView: 2,
                                    },
                                }}
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="aboutSwiperSlide">
                                        <div key={index} className={classes.card}>
                                            <img className={classes.image} src={card.image} alt='' />
                                            <h4 className={`${classes.subtitle} font-20`}>
                                                <Trans>
                                                    {t(card.subtitle)}
                                                </Trans>
                                            </h4>
                                            <p className={`${classes.desc} font-18`}>
                                                {t(card.desc)}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                }
                <p className={`${classes.bottom} font-18`}>
                    <Trans>
                        {t('about_us.bottom')}
                    </Trans>
                </p>
                <div ref={itemWrapRef_01} className={`${classes.stone} ${classes.stone_01}`}>
                    <img ref={itemRef_01} src={stone_01} alt='' />
                </div>
                <div ref={itemWrapRef_02} className={`${classes.stone} ${classes.stone_02}`}>
                    <img ref={itemRef_02} src={stone_02} alt='' />
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
