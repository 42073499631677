import React, { useState, useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import planet from '../../../assets/img/HomePage/Banner/planet.webp';
import comet from '../../../assets/img/HomePage/Banner/comet.webp';
import bat from '../../../assets/img/HomePage/Banner/bat.webp';
import stone_01 from '../../../assets/img/HomePage/Banner/stone_01.webp';
import stone_02 from '../../../assets/img/HomePage/Banner/stone_02.webp';
import stone_03 from '../../../assets/img/HomePage/Banner/stone_03.webp';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();

    const bodyRef = useRef(null);
    const batRef = useRef(null);
    const planetRef = useRef(null);
    const cometRef = useRef(null);

    const stoneWrapRef_01 = useRef(null);
    const stoneRef_01 = useRef(null);
    const stoneWrapRef_02 = useRef(null);
    const stoneRef_02 = useRef(null);
    const stoneWrapRef_03 = useRef(null);
    const stoneRef_03 = useRef(null);

    const animateStoneWrap = (target, y, x, rotate, scale) => {
        gsap.fromTo(target.current, {
            y, x, rotate, scale
        }, {
            y: 0,
            x: 0,
            rotate: 0,
            scale: 1,
            duration: 1.5,
        });
    };

    const animateStone = (target, y) => {
        gsap.timeline().to(target.current, {
            y,
            scrollTrigger: {
                trigger: bodyRef.current,
                start: "top+=150px top",
                scrub: 3,
            },
        });
    };

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            animateStoneWrap(stoneWrapRef_01, -100, -30, 20, 0.8);
            animateStoneWrap(stoneWrapRef_02, -40, 10, 10, 0.8);
            animateStoneWrap(stoneWrapRef_03, -80, 0, -20, 0.8);

            animateStone(stoneRef_01, -70);
            animateStone(stoneRef_02, -150);
            animateStone(stoneRef_03, -100);

            gsap.fromTo(batRef.current, {
                y: 50, x: 50, scale: 0.8,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                duration: 1.5,
            });

            gsap.fromTo(planetRef.current, {
                rotate: 10
            }, {
                rotate: 0,
                duration: 1.5,
            });

            gsap.fromTo(cometRef.current, {
                y: -100, x: -100, scale: 0.8,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                duration: 1.5,
            });
        }
    }, [])

    return (
        <section ref={bodyRef} className={classes.wrap}>
            <div className="container">
                <div className={classes.body}>
                    <div className={classes.content}>
                        <h1 className={`${classes.title} font-80`}>
                            BEST <br />ROI TRAFFIC
                        </h1>
                        <div className={classes.buttons}>
                            <div className={classes.btn}>
                                <Button>
                                    {t('btn.join')}
                                </Button>
                            </div>
                            {/* <div className={classes.btn}>
                                <Button>
                                    {t('btn.join_broker')}
                                </Button>
                            </div> */}
                        </div>
                    </div>
                    <div className={classes.images}>
                        <div className={classes.planet}>
                            <img ref={planetRef} src={planet} alt='' />
                        </div>
                        <div className={classes.comet}>
                            <img ref={cometRef} src={comet} alt='' />
                        </div>
                        <div className={classes.bat}>
                            <img ref={batRef} src={bat} alt='' />
                        </div>
                        <div ref={stoneWrapRef_01} className={classes.stone_01}>
                            <img ref={stoneRef_01} src={stone_01} alt='' />
                        </div>
                        <div ref={stoneWrapRef_02} className={classes.stone_02}>
                            <img ref={stoneRef_02} src={stone_02} alt='' />
                        </div>
                        <div ref={stoneWrapRef_03} className={classes.stone_03}>
                            <img ref={stoneRef_03} src={stone_03} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
