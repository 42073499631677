import React from "react";
import classes from './Step.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/step.svg';

const cards_01 = [
    {
        subtitle: 'step.card_01.subtitle',
        desc: 'step.card_01.desc',
    },
    {
        subtitle: 'step.card_02.subtitle',
        desc: 'step.card_02.desc',
    },

];
const cards_02 = [
    {
        subtitle: 'step.card_03.subtitle',
        desc: 'step.card_03.desc',
    },
    {
        subtitle: 'step.card_04.subtitle',
        desc: 'step.card_04.desc',
    },
];

const Step = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <img className={classes.image} src={image} alt='' />
            <div className="mt container">
                <div className={classes.body}>
                    <div className={classes.head}>
                        <h2 className={`${classes.title} font-74`}>
                            <Trans>
                                {t('step.title')}
                            </Trans>
                        </h2>
                        <p className={`${classes.text} font-18`}>
                            <Trans>
                                {t('step.text')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.cards}>
                        <div className={classes.cards_01}>
                            {cards_01.map((card, index) =>
                                <div className={classes.card} key={index}>
                                    <div className={classes.num}>
                                        0{index + 1}
                                    </div>
                                    <div className={classes.content}>
                                        <h4 className={`${classes.subtitle} font-20`}>
                                            {t(card.subtitle)}
                                        </h4>
                                        <p className={`${classes.desc} font-18`}>
                                            {t(card.desc)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={classes.cards_02}>
                            {cards_02.map((card, index) =>
                                <div className={classes.card} key={index}>
                                    <div className={classes.num}>
                                        0{index + 3}
                                    </div>
                                    <div className={classes.content}>
                                        <h4 className={`${classes.subtitle} font-20`}>
                                            {t(card.subtitle)}
                                        </h4>
                                        <p className={`${classes.desc} font-18`}>
                                            {t(card.desc)}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Step;
