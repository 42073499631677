import React from "react";
import classes from './Marquees.module.scss';
import Marquee from "react-fast-marquee";
import { Trans, useTranslation } from "react-i18next";

const Marquees = () => {
    const { t } = useTranslation();
    return (
        <section className={`${classes.marquees} mt`}>
            <div className={`${classes.marquee} ${classes.marquee_01}`}>
                <Marquee autoFill>
                    <p>
                        <Trans>
                            {t('marquees.text_01')}
                        </Trans>
                    </p>
                </Marquee>
            </div>
            <div className={`${classes.marquee} ${classes.marquee_02}`}>
                <Marquee autoFill direction="right">
                    <p>
                        <Trans>
                            {t('marquees.text_02')}
                        </Trans>
                    </p>
                </Marquee>
            </div>
        </section>
    );
};

export default Marquees;